import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilMemory,
  cilAlarm,
  cilHome,
  cilText,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cibRollupJs,
  cibRoundcube,
  cilBatteryAlert,
  cibSpreaker,
  cilPencil,
  cilTrash,
  cibAddthis,
  cilPlus,
  cilScrubber,
  cilAccountLogout,
  cilLockUnlocked,
  cibVerizon,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilMemory,
  cilAlarm,
  cilAccountLogout,
  cilHome,
  cilText,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cibRollupJs,
  cibRoundcube,
  cilBatteryAlert,
  cibSpreaker,
  cilPencil,
  cilTrash,
  cibAddthis,
  cilPlus,
  cilScrubber,
  cilLockUnlocked,
  cibVerizon
})
