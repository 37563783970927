export const logo = ['90 50', `
  <title>coreui react pro</title>
  <g>
    <path fill="#fefefe" d=" M 0.00 0.00 L 1832.00 0.00 L 1832.00 546.00 L 321.63 546.00 C 363.34 544.49 405.71 534.35 440.60 510.70 C 469.02 491.92 491.08 464.66 506.38 434.43 C 512.45 422.49 517.64 410.10 521.96 397.42 C 483.22 397.34 444.48 397.34 405.74 397.42 C 398.78 409.70 390.12 421.21 379.06 430.10 C 363.16 443.45 343.22 451.80 322.57 453.93 C 298.40 456.33 273.23 452.75 251.58 441.38 C 237.35 434.03 224.98 423.41 214.79 411.11 C 200.79 393.96 192.78 372.76 187.86 351.39 C 187.35 350.24 187.53 348.47 186.24 347.88 C 182.52 347.46 178.77 347.74 175.04 347.69 C 142.09 347.69 109.13 347.71 76.18 347.69 C 81.75 379.57 92.93 410.61 109.89 438.22 C 131.40 472.89 162.20 501.90 198.45 520.71 C 229.53 536.88 264.47 544.65 299.34 546.00 L 0.00 546.00 L 0.00 0.00 Z" />
    <path fill="#691312" d=" M 191.87 30.76 C 223.00 13.14 258.41 3.85 293.98 1.44 C 303.98 1.39 313.94 -0.47 323.94 0.36 C 328.92 1.25 333.98 1.23 339.01 1.45 C 374.56 3.90 409.99 13.63 440.48 32.38 C 461.30 45.29 480.26 61.67 494.32 81.84 C 508.16 101.68 518.21 124.05 525.05 147.19 C 486.63 147.23 448.20 147.17 409.77 147.22 C 397.92 125.95 378.64 108.82 355.90 100.00 C 335.64 92.01 313.30 90.77 291.85 93.35 C 265.52 96.49 240.39 108.80 222.00 127.95 C 201.00 148.62 190.47 177.41 185.19 205.85 C 181.94 223.50 180.18 241.45 180.18 259.40 C 143.60 259.60 107.01 259.44 70.43 259.48 C 71.27 235.47 74.09 211.48 79.47 188.05 C 87.86 151.22 103.32 115.62 127.08 86.05 C 144.96 63.79 166.97 44.79 191.87 30.76 Z" />
    <path fill="#777676" d=" M 1586.16 110.18 C 1598.28 100.08 1614.57 96.72 1630.01 97.14 C 1644.13 96.83 1658.09 99.48 1671.67 103.13 C 1669.92 114.96 1667.10 126.59 1665.05 138.36 C 1655.86 136.39 1646.42 134.88 1637.00 135.78 C 1631.42 136.33 1625.53 139.27 1623.62 144.88 C 1620.11 154.24 1621.83 164.43 1621.43 174.18 C 1633.97 174.43 1646.52 174.23 1659.07 174.28 C 1659.27 187.58 1659.09 200.88 1659.17 214.18 C 1646.58 214.20 1633.99 214.12 1621.41 214.22 C 1621.45 229.31 1621.42 244.39 1621.42 259.47 C 1604.49 259.52 1587.55 259.49 1570.62 259.49 C 1570.62 244.39 1570.62 229.29 1570.61 214.20 C 1561.29 214.16 1551.97 214.18 1542.65 214.18 C 1542.63 200.93 1542.71 187.67 1542.60 174.42 C 1551.93 174.08 1561.28 174.51 1570.62 174.15 C 1570.70 159.46 1570.03 144.50 1573.68 130.13 C 1575.53 122.36 1579.95 115.22 1586.16 110.18 Z" />
    <path fill="#777676" d=" M 829.11 102.23 C 845.93 102.20 862.76 102.19 879.59 102.24 C 879.54 117.70 879.57 133.16 879.58 148.62 C 862.76 148.66 845.94 148.61 829.12 148.65 C 829.19 133.17 828.80 117.70 829.11 102.23 Z" />
    <path fill="#777676" d=" M 708.53 135.94 C 725.51 126.30 742.21 116.17 759.26 106.67 C 759.37 129.18 759.27 151.70 759.32 174.21 C 770.82 174.38 782.32 174.25 793.83 174.29 C 793.85 187.81 793.81 201.32 793.85 214.84 C 782.33 214.87 770.81 214.77 759.29 214.89 C 759.32 229.76 759.31 244.62 759.30 259.48 C 742.38 259.50 725.45 259.50 708.53 259.49 C 708.50 244.64 708.47 229.79 708.55 214.94 C 700.82 214.72 693.09 214.91 685.37 214.82 C 685.38 201.31 685.37 187.81 685.37 174.30 C 693.09 174.22 700.81 174.46 708.52 174.15 C 708.50 161.41 708.48 148.67 708.53 135.94 Z" />
    <path fill="#777676" d=" M 1710.29 124.21 C 1720.46 118.42 1730.43 112.25 1740.73 106.69 C 1740.80 129.22 1740.69 151.76 1740.79 174.29 C 1752.32 174.35 1763.87 174.17 1775.41 174.39 C 1775.30 187.88 1775.43 201.37 1775.34 214.86 C 1763.81 214.82 1752.28 214.85 1740.76 214.83 C 1740.67 229.74 1740.90 244.65 1740.64 259.55 C 1723.75 259.38 1706.87 259.61 1689.99 259.43 C 1689.82 244.57 1689.94 229.71 1689.92 214.84 C 1682.21 214.83 1674.50 214.86 1666.80 214.82 C 1666.63 201.37 1666.83 187.91 1666.69 174.45 C 1674.41 174.12 1682.15 174.39 1689.89 174.26 C 1690.17 161.56 1689.46 148.80 1690.23 136.13 C 1696.61 131.71 1703.65 128.27 1710.29 124.21 Z" />
    <path fill="#777676" d=" M 297.58 182.68 C 311.06 174.13 327.32 171.67 342.97 170.77 C 358.00 170.43 373.21 170.61 387.96 173.86 C 400.37 176.50 412.30 182.63 420.83 192.14 C 427.43 199.46 429.85 209.39 430.92 218.94 C 432.42 232.40 432.76 246.01 431.86 259.52 C 391.23 259.44 350.60 259.54 309.97 259.47 C 323.30 255.02 337.31 253.26 350.98 250.17 C 361.61 247.75 372.31 245.35 382.45 241.26 C 382.60 232.21 382.43 221.45 374.93 215.09 C 367.56 209.63 357.87 209.30 349.05 209.20 C 340.89 208.98 332.13 210.30 325.69 215.70 C 320.40 220.32 317.51 226.93 315.02 233.34 C 299.69 230.73 284.39 227.96 269.07 225.26 C 273.46 208.48 282.72 192.23 297.58 182.68 Z" />
    <path fill="#777676" d=" M 541.47 188.55 C 554.81 176.97 572.42 170.60 590.07 170.65 C 605.89 170.32 622.15 174.50 634.89 184.13 C 645.16 192.05 651.32 204.35 653.45 216.97 C 655.89 231.01 655.41 245.30 655.39 259.49 C 638.48 259.50 621.58 259.49 604.67 259.49 C 604.53 250.64 604.18 241.75 602.72 233.01 C 601.26 224.28 595.79 216.14 587.76 212.20 C 569.73 203.91 545.99 212.85 537.69 230.86 C 533.54 239.80 533.36 249.85 532.43 259.49 C 515.44 259.53 498.44 259.44 481.45 259.53 C 481.24 231.13 481.37 202.72 481.38 174.31 C 497.04 174.25 512.70 174.34 528.37 174.26 C 528.57 183.32 528.35 192.38 528.49 201.44 C 532.89 197.21 536.72 192.42 541.47 188.55 Z" />
    <path fill="#777676" d=" M 978.63 175.72 C 995.12 170.20 1012.88 169.71 1030.05 171.62 C 1045.52 173.48 1061.01 178.80 1072.89 189.17 C 1085.40 200.10 1093.33 215.40 1097.82 231.20 C 1081.14 233.95 1064.57 237.29 1047.87 239.88 C 1046.38 231.58 1042.93 223.19 1036.20 217.78 C 1027.56 210.74 1015.60 209.41 1004.93 211.30 C 994.27 213.12 984.62 219.96 979.47 229.48 C 974.47 238.67 973.23 249.25 972.35 259.50 C 955.04 259.45 937.74 259.56 920.44 259.45 C 921.75 241.98 925.97 224.33 935.48 209.42 C 945.36 193.63 960.90 181.48 978.63 175.72 Z" />
    <path fill="#777676" d=" M 1150.36 183.25 C 1160.77 176.51 1173.04 173.36 1185.21 171.77 C 1200.06 170.10 1215.12 170.26 1229.95 172.05 C 1244.74 174.01 1260.02 178.35 1271.11 188.84 C 1280.35 197.09 1286.73 208.27 1289.89 220.19 C 1273.97 222.96 1258.13 226.17 1242.21 228.90 C 1240.38 223.05 1237.39 217.40 1232.53 213.53 C 1226.01 208.10 1217.24 206.38 1208.96 206.27 C 1199.40 206.17 1189.40 206.31 1180.54 210.34 C 1175.91 212.36 1172.00 216.80 1172.18 222.08 C 1171.88 227.98 1177.06 232.23 1182.12 234.08 C 1209.14 244.64 1238.96 246.54 1265.15 259.43 C 1221.32 259.56 1177.50 259.49 1133.67 259.46 C 1125.35 247.48 1123.49 232.04 1126.16 217.94 C 1128.96 203.65 1138.38 191.24 1150.36 183.25 Z" />
    <path fill="#777676" d=" M 1394.10 175.44 C 1413.74 169.41 1435.08 169.06 1455.00 173.95 C 1487.82 181.95 1514.62 209.57 1522.47 242.31 C 1523.96 247.93 1524.68 253.70 1525.35 259.46 C 1507.90 259.49 1490.46 259.56 1473.01 259.43 C 1471.94 249.11 1468.94 238.74 1462.73 230.30 C 1457.64 223.55 1450.92 217.87 1442.96 214.86 C 1435.38 211.83 1426.98 211.64 1418.99 212.72 C 1404.25 215.32 1391.73 226.31 1386.06 240.00 C 1383.35 246.16 1382.05 252.82 1381.15 259.46 C 1363.81 259.54 1346.47 259.47 1329.14 259.50 C 1330.38 241.73 1336.85 224.47 1346.72 209.70 C 1357.80 193.11 1375.13 181.25 1394.10 175.44 Z" />
    <path fill="#777676" d=" M 829.19 174.29 C 845.98 174.30 862.78 174.28 879.57 174.30 C 879.51 202.71 879.67 231.12 879.50 259.53 C 862.77 259.50 846.04 259.41 829.31 259.57 C 828.89 255.72 828.79 251.83 829.05 247.97 C 829.45 223.40 828.65 198.84 829.19 174.29 Z" />
    <path fill="#691312" d=" M 70.25 279.40 C 70.05 273.47 70.17 267.53 70.42 261.60 C 106.85 261.64 143.27 261.65 179.70 261.60 C 179.78 267.53 179.81 273.47 179.68 279.41 C 143.20 279.34 106.73 279.36 70.25 279.40 Z" />
    <path fill="#777676" d=" M 292.25 266.07 C 296.59 263.99 301.03 261.52 305.98 261.61 C 348.03 261.67 390.08 261.56 432.13 261.66 C 432.29 267.56 431.85 273.44 431.80 279.34 C 415.41 279.43 399.01 279.30 382.62 279.40 C 382.50 277.90 382.38 276.40 382.27 274.91 C 375.22 276.51 368.38 279.49 361.05 279.39 C 332.63 279.29 304.21 279.47 275.79 279.30 C 280.28 273.79 285.86 269.20 292.25 266.07 Z" />
    <path fill="#777676" d=" M 481.47 279.40 C 481.29 273.47 481.29 267.53 481.44 261.59 C 498.43 261.68 515.42 261.58 532.40 261.64 C 532.10 267.48 532.45 273.34 532.00 279.17 C 515.17 279.67 498.31 279.18 481.47 279.40 Z" />
    <path fill="#777676" d=" M 604.74 261.65 C 621.63 261.61 638.52 261.60 655.40 261.65 C 655.42 267.55 655.42 273.46 655.39 279.37 C 638.51 279.36 621.64 279.38 604.76 279.36 C 604.75 273.46 604.75 267.55 604.74 261.65 Z" />
    <path fill="#777676" d=" M 708.54 261.61 C 725.46 261.65 742.37 261.59 759.29 261.64 C 759.32 267.55 759.32 273.46 759.29 279.38 C 742.37 279.36 725.46 279.38 708.54 279.37 C 708.49 273.45 708.49 267.53 708.54 261.61 Z" />
    <path fill="#777676" d=" M 829.16 261.66 C 845.94 261.57 862.71 261.67 879.49 261.60 C 879.61 267.53 879.60 273.46 879.49 279.39 C 862.64 279.31 845.80 279.45 828.95 279.32 C 828.74 273.43 829.26 267.55 829.16 261.66 Z" />
    <path fill="#777676" d=" M 920.43 279.41 C 920.25 273.47 920.23 267.52 920.46 261.59 C 937.75 261.70 955.04 261.55 972.34 261.66 C 972.31 267.57 972.29 273.48 972.57 279.38 C 955.19 279.38 937.81 279.32 920.43 279.41 Z" />
    <path fill="#777676" d=" M 1135.53 261.71 C 1178.02 261.49 1220.50 261.73 1262.99 261.59 C 1266.05 261.65 1269.40 261.19 1272.15 262.89 C 1279.15 266.84 1285.50 272.25 1289.50 279.31 C 1247.65 279.47 1205.79 279.28 1163.94 279.40 C 1161.53 279.46 1159.13 279.08 1156.95 278.06 C 1148.68 274.39 1140.96 269.04 1135.53 261.71 Z" />
    <path fill="#777676" d=" M 1328.76 261.64 C 1346.11 261.62 1363.47 261.60 1380.83 261.65 C 1380.53 267.54 1380.51 273.44 1380.78 279.34 C 1363.61 279.45 1346.44 279.26 1329.28 279.44 C 1328.34 273.55 1328.80 267.57 1328.76 261.64 Z" />
    <path fill="#777676" d=" M 1473.38 261.62 C 1490.73 261.61 1508.08 261.65 1525.44 261.61 C 1525.83 267.53 1525.82 273.47 1525.37 279.39 C 1508.04 279.35 1490.72 279.37 1473.39 279.37 C 1473.67 273.46 1473.67 267.54 1473.38 261.62 Z" />
    <path fill="#777676" d=" M 1570.63 261.63 C 1587.56 261.62 1604.49 261.61 1621.42 261.64 C 1621.42 267.55 1621.44 273.46 1621.40 279.37 C 1604.48 279.36 1587.56 279.38 1570.64 279.36 C 1570.59 273.45 1570.62 267.54 1570.63 261.63 Z" />
    <path fill="#777676" d=" M 1689.95 261.65 C 1706.85 261.55 1723.75 261.72 1740.65 261.57 C 1740.81 267.52 1740.83 273.48 1740.63 279.43 C 1723.84 279.35 1707.05 279.29 1690.27 279.46 C 1689.67 273.54 1689.93 267.59 1689.95 261.65 Z" />
    <path fill="#691312" d=" M 70.17 281.54 C 106.70 281.54 143.23 281.57 179.76 281.52 C 180.55 288.13 180.21 294.81 180.74 301.44 C 144.04 301.46 107.35 301.43 70.65 301.45 C 70.37 294.82 70.17 288.18 70.17 281.54 Z" />
    <path fill="#777676" d=" M 274.09 281.48 C 301.07 281.64 328.06 281.40 355.05 281.60 C 345.56 284.01 335.73 285.66 326.83 289.94 C 321.90 292.28 318.36 296.65 315.89 301.40 C 298.98 301.48 282.07 301.42 265.16 301.43 C 266.75 294.26 269.63 287.35 274.09 281.48 Z" />
    <path fill="#777676" d=" M 1166.02 281.62 C 1207.70 281.45 1249.39 281.50 1291.08 281.60 C 1293.99 287.84 1295.64 294.56 1296.40 301.39 C 1277.62 301.51 1258.84 301.36 1240.07 301.48 C 1235.48 301.61 1231.29 299.51 1226.92 298.47 C 1206.44 293.59 1185.78 289.01 1166.02 281.62 Z" />
    <path fill="#777676" d=" M 381.78 301.43 C 382.53 294.81 382.37 288.15 382.48 281.51 C 398.95 281.58 415.41 281.46 431.88 281.57 C 431.88 288.21 431.72 294.85 431.58 301.49 C 414.98 301.37 398.38 301.47 381.78 301.43 Z" />
    <path fill="#777676" d=" M 481.43 301.47 C 481.29 294.81 481.33 288.15 481.42 281.50 C 498.35 281.60 515.28 281.44 532.22 281.58 C 532.18 288.18 532.21 294.79 532.19 301.39 C 515.27 301.51 498.35 301.36 481.43 301.47 Z" />
    <path fill="#777676" d=" M 604.75 281.53 C 621.63 281.54 638.52 281.49 655.41 281.56 C 655.40 288.18 655.43 294.80 655.39 301.42 C 638.52 301.45 621.64 301.44 604.77 301.42 C 604.73 294.79 604.76 288.16 604.75 281.53 Z" />
    <path fill="#777676" d=" M 708.51 281.52 C 725.45 281.55 742.38 281.49 759.32 281.55 C 759.30 288.18 759.32 294.81 759.30 301.44 C 742.38 301.43 725.45 301.44 708.53 301.43 C 708.48 294.80 708.51 288.16 708.51 281.52 Z" />
    <path fill="#777676" d=" M 829.14 281.54 C 845.93 281.49 862.71 281.57 879.50 281.50 C 879.60 288.16 879.60 294.81 879.51 301.47 C 862.71 301.38 845.92 301.49 829.13 301.41 C 828.51 294.79 829.27 288.17 829.14 281.54 Z" />
    <path fill="#777676" d=" M 923.38 301.50 C 921.62 294.98 920.87 288.24 920.45 281.52 C 937.85 281.49 955.24 281.62 972.64 281.46 C 973.58 288.15 974.54 294.84 976.21 301.41 C 958.60 301.51 940.99 301.34 923.38 301.50 Z" />
    <path fill="#777676" d=" M 1329.26 281.53 C 1346.55 281.55 1363.85 281.48 1381.14 281.57 C 1381.82 288.37 1383.44 295.04 1385.81 301.45 C 1368.05 301.44 1350.29 301.39 1332.53 301.48 C 1330.74 294.97 1329.67 288.27 1329.26 281.53 Z" />
    <path fill="#777676" d=" M 1472.99 281.58 C 1490.43 281.48 1507.87 281.52 1525.32 281.55 C 1524.54 288.29 1523.67 295.05 1521.46 301.50 C 1503.77 301.37 1486.08 301.45 1468.38 301.45 C 1470.71 295.04 1472.39 288.38 1472.99 281.58 Z" />
    <path fill="#777676" d=" M 1570.63 281.54 C 1587.56 281.53 1604.49 281.50 1621.43 281.55 C 1621.43 288.18 1621.42 294.80 1621.42 301.42 C 1604.49 301.45 1587.56 301.43 1570.63 301.43 C 1570.60 294.80 1570.61 288.17 1570.63 281.54 Z" />
    <path fill="#777676" d=" M 1689.93 281.57 C 1706.88 281.50 1723.83 281.50 1740.79 281.56 C 1740.69 288.21 1740.85 294.86 1740.65 301.50 C 1723.75 301.32 1706.84 301.53 1689.94 301.39 C 1689.88 294.78 1689.90 288.17 1689.93 281.57 Z" />
    <path fill="#777676" d=" M 1048.96 301.45 C 1049.33 298.56 1049.67 295.60 1050.78 292.88 C 1060.59 294.12 1070.30 296.11 1080.10 297.54 C 1086.82 298.81 1093.68 299.42 1100.30 301.23 C 1083.20 301.74 1066.07 301.28 1048.96 301.45 Z" />
    <path fill="#691312" d=" M 70.61 303.62 C 107.43 303.56 144.24 303.60 181.06 303.61 C 181.31 310.26 182.27 316.85 182.77 323.49 C 146.03 323.52 109.28 323.47 72.54 323.52 C 71.49 316.92 71.54 310.23 70.61 303.62 Z" />
    <path fill="#777676" d=" M 264.55 323.52 C 263.55 316.90 263.77 310.19 264.69 303.58 C 281.44 303.59 298.18 303.56 314.92 303.60 C 313.81 310.48 315.20 317.50 318.69 323.51 C 300.64 323.51 282.60 323.50 264.55 323.52 Z" />
    <path fill="#777676" d=" M 372.26 323.47 C 377.27 317.89 380.79 311.06 381.76 303.57 C 398.41 303.64 415.06 303.48 431.71 303.66 C 431.58 310.27 431.84 316.88 432.27 323.47 C 412.26 323.53 392.26 323.53 372.26 323.47 Z" />
    <path fill="#777676" d=" M 481.37 323.52 C 481.38 316.86 481.27 310.20 481.45 303.54 C 498.27 303.58 515.09 303.64 531.90 303.51 C 532.39 310.07 532.32 316.68 532.07 323.25 C 515.19 323.88 498.26 323.31 481.37 323.52 Z" />
    <path fill="#777676" d=" M 604.75 303.59 C 621.64 303.59 638.52 303.53 655.41 303.62 C 655.41 310.24 655.42 316.87 655.41 323.49 C 638.53 323.53 621.65 323.49 604.77 323.51 C 604.74 316.87 604.74 310.23 604.75 303.59 Z" />
    <path fill="#777676" d=" M 708.52 303.59 C 725.48 303.55 742.44 303.60 759.40 303.57 C 759.58 310.26 759.07 317.18 761.54 323.55 C 743.96 323.41 726.37 323.62 708.79 323.44 C 708.64 316.82 708.44 310.21 708.52 303.59 Z" />
    <path fill="#777676" d=" M 829.13 303.64 C 845.92 303.48 862.72 303.65 879.51 303.56 C 879.60 310.21 879.57 316.86 879.55 323.51 C 862.76 323.53 845.98 323.46 829.20 323.55 C 828.55 316.92 829.37 310.28 829.13 303.64 Z" />
    <path fill="#777676" d=" M 923.75 303.64 C 941.53 303.52 959.32 303.59 977.11 303.60 C 979.92 311.60 985.30 318.34 991.94 323.53 C 971.51 323.48 951.08 323.53 930.66 323.50 C 927.65 317.15 925.26 310.51 923.75 303.64 Z" />
    <path fill="#777676" d=" M 1035.17 323.56 C 1041.51 318.49 1045.47 311.22 1047.94 303.61 C 1065.19 303.55 1082.43 303.59 1099.68 303.59 C 1098.15 310.41 1095.84 317.01 1093.12 323.44 C 1073.80 323.63 1054.49 323.40 1035.17 323.56 Z" />
    <path fill="#777676" d=" M 1241.26 303.57 C 1259.65 303.60 1278.04 303.56 1296.43 303.59 C 1296.74 310.30 1295.75 316.95 1294.12 323.44 C 1277.24 323.59 1260.36 323.47 1243.49 323.51 C 1244.79 319.85 1246.05 315.97 1245.37 312.05 C 1245.00 308.82 1243.05 306.15 1241.26 303.57 Z" />
    <path fill="#777676" d=" M 1333.01 303.61 C 1348.34 303.53 1363.67 303.63 1379.00 303.56 C 1381.58 303.58 1384.21 303.39 1386.79 303.84 C 1391.06 311.60 1396.99 318.29 1404.22 323.44 C 1383.02 323.58 1361.82 323.49 1340.63 323.49 C 1337.40 317.15 1334.76 310.50 1333.01 303.61 Z" />
    <path fill="#777676" d=" M 1463.32 310.39 C 1464.89 308.22 1465.83 305.52 1467.86 303.71 C 1485.55 303.40 1503.27 303.64 1520.97 303.61 C 1518.89 310.55 1515.87 317.17 1512.31 323.48 C 1491.56 323.55 1470.81 323.47 1450.06 323.52 C 1454.92 319.63 1459.95 315.73 1463.32 310.39 Z" />
    <path fill="#777676" d=" M 1570.62 303.59 C 1587.55 303.58 1604.49 303.56 1621.42 303.60 C 1621.43 310.23 1621.41 316.85 1621.45 323.48 C 1604.50 323.54 1587.56 323.50 1570.62 323.50 C 1570.63 316.86 1570.60 310.23 1570.62 303.59 Z" />
    <path fill="#777676" d=" M 1689.90 303.62 C 1706.85 303.55 1723.80 303.55 1740.76 303.62 C 1741.14 310.28 1740.82 317.10 1742.84 323.54 C 1725.38 323.46 1707.92 323.53 1690.47 323.51 C 1690.28 316.88 1689.86 310.25 1689.90 303.62 Z" />
    <path fill="#777676" d=" M 1118.21 314.26 C 1135.02 311.22 1151.96 308.64 1168.87 306.10 C 1170.27 312.41 1173.11 318.30 1176.94 323.49 C 1158.27 323.46 1139.60 323.64 1120.93 323.40 C 1119.92 320.39 1118.66 317.42 1118.21 314.26 Z" />
    <path fill="#691312" d=" M 72.70 325.63 C 109.52 325.67 146.34 325.64 183.17 325.64 C 184.07 332.30 185.23 338.94 186.49 345.55 C 149.56 345.56 112.62 345.54 75.68 345.56 C 74.46 338.95 73.55 332.29 72.70 325.63 Z" />
    <path fill="#777676" d=" M 264.90 325.68 C 283.35 325.55 301.81 325.72 320.27 325.59 C 323.64 328.71 327.11 331.95 331.52 333.50 C 342.25 337.48 354.55 335.07 364.10 329.19 C 366.63 327.78 368.94 325.39 372.06 325.62 C 392.15 325.66 412.25 325.62 432.34 325.65 C 432.59 332.37 434.04 338.95 435.04 345.58 C 382.02 345.55 329.00 345.55 275.98 345.58 C 274.84 345.33 273.14 345.93 272.43 344.72 C 268.50 339.06 266.26 332.39 264.90 325.68 Z" />
    <path fill="#777676" d=" M 481.37 345.57 C 481.38 338.91 481.26 332.26 481.46 325.60 C 498.31 325.82 515.18 325.35 532.01 325.83 C 532.45 332.40 532.19 338.99 532.07 345.56 C 515.17 345.56 498.27 345.55 481.37 345.57 Z" />
    <path fill="#777676" d=" M 604.76 325.65 C 621.64 325.63 638.52 325.63 655.40 325.65 C 655.43 332.26 655.38 338.86 655.44 345.47 C 638.59 345.69 621.73 345.46 604.87 345.60 C 604.64 338.95 604.77 332.30 604.76 325.65 Z" />
    <path fill="#777676" d=" M 709.17 325.63 C 726.43 325.65 743.70 325.64 760.96 325.62 C 764.58 325.50 767.46 328.29 771.01 328.63 C 778.75 329.55 785.91 325.55 793.58 325.36 C 794.63 332.06 795.32 338.82 795.85 345.58 C 768.11 345.51 740.36 345.63 712.62 345.52 C 710.74 339.04 709.32 332.39 709.17 325.63 Z" />
    <path fill="#777676" d=" M 829.15 325.67 C 845.93 325.58 862.71 325.69 879.50 325.62 C 879.62 332.26 879.54 338.90 879.57 345.55 C 862.77 345.60 845.98 345.51 829.19 345.59 C 829.09 338.95 829.14 332.31 829.15 325.67 Z" />
    <path fill="#777676" d=" M 931.83 325.70 C 951.88 325.53 971.94 325.72 992.00 325.60 C 994.73 325.49 997.39 326.21 999.94 327.15 C 1006.91 329.71 1014.56 330.12 1021.85 328.89 C 1026.32 328.18 1030.35 325.53 1034.96 325.61 C 1053.96 325.69 1072.96 325.59 1091.96 325.66 C 1088.47 332.74 1084.53 340.00 1078.64 345.35 C 1047.13 345.84 1015.56 345.42 984.02 345.56 C 971.49 345.40 958.93 345.87 946.42 345.36 C 940.06 340.21 935.72 332.84 931.83 325.70 Z" />
    <path fill="#777676" d=" M 1122.08 325.66 C 1141.08 325.63 1160.09 325.62 1179.10 325.66 C 1187.41 333.24 1199.03 335.65 1209.99 335.43 C 1221.21 335.46 1233.39 333.58 1241.93 325.64 C 1259.02 325.68 1276.12 325.56 1293.21 325.70 C 1290.70 333.08 1286.30 339.60 1281.32 345.52 C 1233.88 345.62 1186.43 345.51 1138.98 345.58 C 1137.46 345.43 1135.60 345.86 1134.53 344.51 C 1129.27 339.06 1125.13 332.56 1122.08 325.66 Z" />
    <path fill="#777676" d=" M 1341.97 325.67 C 1362.97 325.58 1383.98 325.70 1404.98 325.60 C 1407.68 325.50 1410.31 326.28 1412.85 327.17 C 1422.43 330.57 1433.19 330.29 1442.71 326.80 C 1446.93 325.13 1451.52 325.67 1455.95 325.63 C 1474.28 325.68 1492.61 325.59 1510.94 325.67 C 1506.32 332.84 1501.12 339.87 1494.51 345.30 C 1486.02 345.97 1477.46 345.35 1468.95 345.56 C 1432.97 345.58 1396.99 345.52 1361.01 345.59 C 1359.47 345.46 1357.64 345.82 1356.50 344.55 C 1350.66 339.12 1345.81 332.65 1341.97 325.67 Z" />
    <path fill="#777676" d=" M 1570.63 325.64 C 1587.55 325.64 1604.48 325.62 1621.42 325.66 C 1621.44 332.27 1621.40 338.87 1621.45 345.48 C 1604.50 345.63 1587.55 345.58 1570.60 345.50 C 1570.65 338.88 1570.59 332.26 1570.63 325.64 Z" />
    <path fill="#777676" d=" M 1690.52 325.66 C 1707.66 325.61 1724.80 325.68 1741.94 325.61 C 1745.93 325.27 1749.01 328.52 1752.92 328.67 C 1760.58 329.48 1767.61 325.40 1775.22 325.45 C 1776.04 332.13 1776.72 338.82 1777.54 345.50 C 1750.35 345.63 1723.16 345.55 1695.97 345.54 C 1693.75 346.11 1693.60 343.52 1693.08 342.07 C 1691.64 336.71 1691.03 331.17 1690.52 325.66 Z" />
    <path fill="#691312" d=" M 109.89 438.22 C 92.93 410.61 81.75 379.57 76.18 347.69 C 109.13 347.71 142.09 347.69 175.04 347.69 C 178.77 347.74 182.52 347.46 186.24 347.88 C 187.53 348.47 187.35 350.24 187.86 351.39 C 192.78 372.76 200.79 393.96 214.79 411.11 C 224.98 423.41 237.35 434.03 251.58 441.38 C 273.23 452.75 298.40 456.33 322.57 453.93 C 343.22 451.80 363.16 443.45 379.06 430.10 C 390.12 421.21 398.78 409.70 405.74 397.42 C 444.48 397.34 483.22 397.34 521.96 397.42 C 517.64 410.10 512.45 422.49 506.38 434.43 C 491.08 464.66 469.02 491.92 440.60 510.70 C 405.71 534.35 363.34 544.49 321.63 546.00 L 299.34 546.00 C 264.47 544.65 229.53 536.88 198.45 520.71 C 162.20 501.90 131.40 472.89 109.89 438.22 Z" />
    <path fill="#777676" d=" M 274.66 347.80 C 311.17 347.64 347.68 347.62 384.19 347.81 C 368.86 362.75 347.52 371.71 325.99 370.87 C 306.71 371.20 286.11 364.04 274.66 347.80 Z" />
    <path fill="#777676" d=" M 387.21 347.68 C 403.49 347.74 419.77 347.63 436.05 347.74 C 437.94 353.89 440.48 359.80 443.08 365.67 C 426.38 365.78 409.68 365.68 392.99 365.71 C 390.80 359.79 388.73 353.81 387.21 347.68 Z" />
    <path fill="#777676" d=" M 481.35 365.69 C 481.37 359.68 481.27 353.67 481.46 347.66 C 498.37 347.75 515.28 347.65 532.19 347.71 C 532.20 353.71 532.25 359.70 532.14 365.70 C 515.21 365.72 498.28 365.73 481.35 365.69 Z" />
    <path fill="#777676" d=" M 604.77 347.69 C 621.64 347.71 638.51 347.67 655.38 347.72 C 655.43 353.68 655.38 359.65 655.44 365.61 C 638.56 365.86 621.68 365.63 604.80 365.73 C 604.72 359.72 604.73 353.71 604.77 347.69 Z" />
    <path fill="#777676" d=" M 713.70 347.71 C 740.13 347.66 766.55 347.74 792.98 347.67 C 794.07 347.76 796.27 347.42 796.37 349.04 C 796.98 353.83 797.76 358.66 797.59 363.50 C 781.84 370.31 764.16 372.03 747.21 370.22 C 733.68 368.11 718.56 361.61 713.70 347.71 Z" />
    <path fill="#777676" d=" M 829.23 347.72 C 845.87 347.75 862.53 347.55 879.17 347.81 C 880.14 353.68 879.28 359.74 879.57 365.69 C 862.79 365.76 846.00 365.67 829.22 365.74 C 828.61 359.74 829.09 353.73 829.23 347.72 Z" />
    <path fill="#777676" d=" M 948.60 347.79 C 991.22 347.65 1033.84 347.61 1076.46 347.81 C 1065.43 359.85 1049.61 366.42 1033.77 369.24 C 1021.48 371.23 1008.90 371.39 996.53 370.05 C 978.83 367.78 961.26 360.64 948.60 347.79 Z" />
    <path fill="#777676" d=" M 1138.25 347.85 C 1163.15 347.50 1188.07 347.80 1212.98 347.70 C 1234.88 347.77 1256.78 347.55 1278.67 347.81 C 1265.11 362.73 1244.45 368.54 1224.98 370.31 C 1203.31 371.80 1180.78 371.00 1160.46 362.54 C 1152.31 358.98 1144.36 354.40 1138.25 347.85 Z" />
    <path fill="#777676" d=" M 1360.42 347.80 C 1404.15 347.63 1447.88 347.61 1491.61 347.81 C 1479.63 359.32 1463.72 366.15 1447.55 369.26 C 1426.69 372.76 1404.78 370.88 1385.20 362.70 C 1376.30 358.97 1367.50 354.46 1360.42 347.80 Z" />
    <path fill="#777676" d=" M 1570.62 347.70 C 1587.54 347.70 1604.47 347.70 1621.40 347.70 C 1621.46 353.67 1621.37 359.64 1621.49 365.62 C 1604.51 365.78 1587.54 365.78 1570.57 365.62 C 1570.65 359.65 1570.59 353.67 1570.62 347.70 Z" />
    <path fill="#777676" d=" M 1695.09 347.73 C 1722.56 347.62 1750.04 347.79 1777.52 347.65 C 1778.41 352.85 1778.76 358.13 1779.48 363.36 C 1769.86 367.50 1759.50 369.85 1749.07 370.60 C 1738.69 371.19 1728.00 371.22 1718.10 367.65 C 1708.22 364.43 1698.74 357.85 1695.09 347.73 Z" />
    <path fill="#777676" d=" M 1239.33 406.42 C 1243.30 401.51 1250.02 400.15 1256.04 400.27 C 1261.71 400.36 1268.01 401.56 1271.94 406.02 C 1274.39 408.61 1275.14 412.19 1275.85 415.57 C 1272.43 416.03 1268.98 416.55 1265.53 416.38 C 1263.89 414.03 1263.13 410.54 1259.96 409.77 C 1256.06 408.68 1251.47 408.77 1248.00 411.04 C 1245.36 412.73 1246.26 416.83 1248.94 417.99 C 1256.54 421.84 1266.15 421.38 1272.71 427.34 C 1280.19 434.25 1277.80 448.17 1268.55 452.35 C 1261.01 455.62 1251.98 455.68 1244.37 452.60 C 1238.15 450.07 1235.07 443.49 1234.06 437.23 C 1237.43 436.81 1240.83 436.58 1244.22 436.30 C 1245.54 439.94 1247.34 444.15 1251.46 445.28 C 1255.92 446.34 1261.55 446.41 1264.97 442.84 C 1267.32 440.37 1266.88 435.89 1263.58 434.51 C 1256.48 431.29 1248.08 431.16 1241.66 426.41 C 1235.54 421.93 1234.33 412.17 1239.33 406.42 Z" />
    <path fill="#777676" d=" M 1335.14 403.06 C 1340.03 399.09 1346.83 400.08 1352.48 401.58 C 1352.05 403.90 1351.65 406.23 1351.29 408.57 C 1348.88 408.61 1346.34 408.07 1344.05 408.92 C 1341.74 410.29 1342.71 413.47 1342.18 415.67 C 1344.72 415.71 1347.29 415.71 1349.86 415.71 C 1350.08 418.37 1350.20 421.04 1350.24 423.71 C 1347.60 423.79 1344.97 423.88 1342.35 423.97 C 1342.41 434.01 1342.39 444.05 1342.36 454.09 C 1338.99 454.11 1335.64 454.10 1332.29 453.96 C 1332.33 443.95 1332.31 433.94 1332.31 423.94 C 1330.40 423.85 1328.51 423.78 1326.63 423.70 C 1326.64 421.14 1326.63 418.59 1326.62 416.04 C 1328.49 415.83 1330.38 415.69 1332.28 415.53 C 1332.52 411.34 1331.60 406.17 1335.14 403.06 Z" />
    <path fill="#777676" d=" M 1081.44 401.14 C 1084.98 401.09 1088.52 401.08 1092.07 401.15 C 1091.88 418.80 1092.45 436.45 1091.89 454.11 C 1088.39 454.12 1084.89 454.08 1081.41 453.89 C 1081.49 436.31 1081.43 418.72 1081.44 401.14 Z" />
    <path fill="#777676" d=" M 1102.16 401.11 C 1105.56 401.10 1108.96 401.10 1112.37 401.11 C 1112.39 407.63 1112.46 414.16 1112.34 420.69 C 1115.45 417.57 1119.39 414.79 1124.03 415.05 C 1129.79 414.67 1135.97 418.55 1136.55 424.62 C 1137.74 434.33 1137.07 444.17 1137.06 453.94 C 1133.61 454.13 1130.16 454.12 1126.72 453.94 C 1126.71 446.29 1126.80 438.64 1126.71 430.99 C 1126.57 428.89 1126.74 426.46 1125.13 424.85 C 1122.67 422.32 1118.33 422.76 1115.64 424.67 C 1113.07 426.64 1112.54 430.09 1112.44 433.12 C 1112.29 440.11 1112.49 447.11 1112.36 454.10 C 1108.96 454.10 1105.57 454.09 1102.18 454.07 C 1102.15 436.42 1102.18 418.76 1102.16 401.11 Z" />
    <path fill="#777676" d=" M 1547.32 401.16 C 1550.69 401.10 1554.07 401.09 1557.46 401.10 C 1557.49 418.78 1557.52 436.46 1557.45 454.15 C 1554.06 454.13 1550.68 454.06 1547.32 453.87 C 1547.40 436.30 1547.39 418.73 1547.32 401.16 Z" />
    <path fill="#777676" d=" M 1589.12 401.71 C 1591.92 401.73 1594.73 401.74 1597.55 401.73 C 1597.55 404.68 1597.56 407.64 1597.59 410.60 C 1594.67 410.60 1591.77 410.58 1588.87 410.57 C 1588.76 407.61 1588.80 404.65 1589.12 401.71 Z" />
    <path fill="#777676" d=" M 1573.48 401.74 C 1576.39 401.75 1579.30 401.76 1582.23 401.78 C 1582.22 404.70 1582.21 407.62 1582.23 410.54 C 1579.30 410.58 1576.38 410.59 1573.47 410.60 C 1573.45 407.64 1573.46 404.69 1573.48 401.74 Z" />
    <path fill="#777676" d=" M 1355.70 408.51 C 1359.07 406.43 1362.51 404.45 1366.10 402.75 C 1366.20 407.07 1366.19 411.39 1366.18 415.72 C 1368.40 415.72 1370.63 415.74 1372.88 415.77 C 1372.86 418.45 1372.85 421.13 1372.84 423.81 C 1370.51 423.82 1368.18 423.84 1365.87 423.88 C 1366.60 431.33 1365.09 439.04 1366.92 446.33 C 1368.90 446.69 1370.92 446.35 1372.93 446.22 C 1373.16 448.65 1373.38 451.08 1373.61 453.52 C 1368.89 454.73 1363.40 455.84 1359.05 452.96 C 1356.11 450.99 1356.06 447.10 1355.81 443.95 C 1355.58 437.30 1355.76 430.64 1355.74 423.98 C 1354.22 423.89 1352.72 423.81 1351.25 423.73 C 1351.27 421.04 1351.28 418.37 1351.33 415.69 C 1352.78 415.70 1354.24 415.72 1355.73 415.74 C 1355.71 413.33 1355.71 410.92 1355.70 408.51 Z" />
    <path fill="#777676" d=" M 1146.82 415.92 C 1149.75 415.56 1152.72 415.72 1155.69 415.68 C 1155.88 417.19 1156.08 418.70 1156.31 420.23 C 1158.38 417.94 1160.60 415.12 1164.00 415.10 C 1166.73 414.71 1169.27 415.94 1171.54 417.32 C 1170.38 420.40 1169.34 423.52 1168.34 426.66 C 1166.13 425.98 1163.79 424.81 1161.47 425.63 C 1158.99 426.54 1157.95 429.25 1157.53 431.65 C 1156.34 439.05 1156.90 446.57 1156.77 454.03 C 1153.39 454.11 1150.00 454.10 1146.63 454.00 C 1146.77 441.30 1146.36 428.60 1146.82 415.92 Z" />
    <path fill="#777676" d=" M 1176.72 421.70 C 1182.95 412.81 1198.01 412.76 1204.47 421.42 C 1208.03 426.06 1208.54 432.16 1208.58 437.82 C 1200.04 437.85 1191.50 437.69 1182.97 437.93 C 1183.49 442.15 1186.25 446.68 1190.99 446.66 C 1194.56 447.25 1196.78 444.10 1198.40 441.45 C 1201.62 442.00 1204.85 442.50 1208.08 443.11 C 1206.68 446.18 1205.16 449.39 1202.34 451.42 C 1196.79 455.52 1189.03 455.68 1182.79 453.15 C 1177.90 451.24 1174.75 446.45 1173.61 441.48 C 1172.03 434.87 1172.74 427.36 1176.72 421.70 Z" />
    <path fill="#777676" d=" M 1300.23 415.26 C 1306.37 414.40 1313.14 415.88 1317.57 420.46 C 1325.96 428.44 1325.01 443.83 1315.80 450.81 C 1307.16 457.48 1292.72 455.64 1286.79 446.25 C 1282.32 438.48 1282.76 427.64 1288.99 420.92 C 1291.86 417.70 1296.00 415.85 1300.23 415.26 Z" />
    <path fill="#777676" d=" M 1443.28 416.41 C 1448.67 414.52 1454.65 414.72 1460.17 415.92 C 1463.66 416.87 1467.08 419.58 1467.55 423.36 C 1468.70 433.58 1466.27 444.21 1469.83 454.13 C 1466.64 454.07 1463.39 454.42 1460.27 453.67 C 1459.68 452.39 1459.28 451.02 1458.87 449.70 C 1455.63 451.99 1452.22 454.59 1448.09 454.74 C 1443.45 455.11 1438.17 453.51 1435.75 449.27 C 1432.63 444.17 1434.73 436.61 1440.40 434.32 C 1446.03 432.09 1452.17 431.63 1457.98 429.96 C 1457.61 427.93 1458.20 425.15 1456.05 423.97 C 1453.88 422.94 1451.35 423.00 1449.05 423.34 C 1446.46 423.69 1445.47 426.46 1444.06 428.28 C 1441.07 427.72 1438.09 427.23 1435.16 426.52 C 1436.86 422.54 1438.90 418.02 1443.28 416.41 Z" />
    <path fill="#777676" d=" M 1487.23 420.32 C 1489.42 418.13 1491.58 415.16 1495.00 415.09 C 1497.76 414.69 1500.31 415.96 1502.63 417.32 C 1501.46 420.41 1500.40 423.55 1499.41 426.70 C 1497.21 425.99 1494.83 424.75 1492.51 425.65 C 1489.65 426.80 1488.64 430.11 1488.44 432.93 C 1487.93 439.90 1488.34 446.91 1488.15 453.90 C 1484.68 454.11 1481.20 454.12 1477.74 454.03 C 1477.68 441.26 1477.73 428.50 1477.70 415.74 C 1480.86 415.72 1484.02 415.72 1487.19 415.76 C 1487.19 417.27 1487.20 418.80 1487.23 420.32 Z" />
    <path fill="#777676" d=" M 1508.16 421.22 C 1514.64 412.82 1529.21 412.93 1535.57 421.43 C 1539.16 426.07 1539.59 432.20 1539.63 437.86 C 1531.12 437.77 1522.61 437.80 1514.11 437.84 C 1514.52 442.11 1517.28 446.63 1522.04 446.67 C 1525.64 447.30 1527.87 444.18 1529.46 441.48 C 1532.61 441.97 1535.81 442.30 1538.91 443.19 C 1537.49 448.63 1532.89 453.00 1527.43 454.24 C 1521.99 455.38 1515.93 454.86 1511.24 451.71 C 1506.47 448.47 1504.36 442.57 1503.99 437.04 C 1503.66 431.53 1504.70 425.66 1508.16 421.22 Z" />
    <path fill="#777676" d=" M 1582.26 415.19 C 1589.31 414.16 1597.11 416.77 1601.28 422.76 C 1604.38 426.80 1605.21 432.05 1604.77 437.03 C 1604.51 445.06 1598.21 452.27 1590.52 454.22 C 1583.26 455.83 1574.81 453.99 1569.89 448.15 C 1564.45 441.32 1564.14 431.02 1568.62 423.62 C 1571.53 418.86 1576.75 415.82 1582.26 415.19 Z" />
    <path fill="#777676" d=" M 1615.47 417.67 C 1621.03 414.37 1628.02 414.61 1634.16 415.89 C 1639.29 417.11 1642.23 421.92 1643.94 426.54 C 1640.92 427.22 1637.85 427.70 1634.83 428.29 C 1633.14 425.95 1631.25 423.05 1627.99 423.17 C 1625.19 423.07 1619.72 422.78 1620.10 426.95 C 1626.75 431.22 1635.80 429.76 1642.07 434.95 C 1645.16 437.52 1645.30 442.01 1644.43 445.64 C 1642.87 450.64 1637.80 453.57 1632.89 454.38 C 1627.05 455.24 1620.57 454.99 1615.56 451.54 C 1612.38 449.39 1610.59 445.89 1609.11 442.48 C 1612.49 441.70 1615.95 441.26 1619.43 441.07 C 1621.15 446.99 1628.64 448.02 1633.46 445.39 C 1635.07 444.53 1634.83 442.48 1634.27 441.07 C 1627.69 438.22 1619.69 438.73 1613.91 434.07 C 1608.78 429.80 1609.95 421.08 1615.47 417.67 Z" />
    <path fill="#777676" d=" M 1716.90 415.48 C 1721.52 414.51 1726.96 415.06 1730.50 418.50 C 1733.19 421.24 1733.66 425.30 1733.67 428.97 C 1733.68 437.29 1733.64 445.61 1733.67 453.93 C 1730.28 454.08 1726.90 454.12 1723.53 454.12 C 1723.41 446.46 1723.60 438.80 1723.46 431.15 C 1723.24 428.67 1723.10 425.63 1720.81 424.13 C 1717.29 421.88 1712.01 423.51 1710.27 427.28 C 1709.12 429.67 1709.20 432.39 1709.14 434.98 C 1709.12 441.29 1709.16 447.60 1709.14 453.92 C 1705.69 454.08 1702.25 454.12 1698.82 454.13 C 1698.76 441.33 1698.81 428.54 1698.78 415.74 C 1701.96 415.72 1705.14 415.73 1708.34 415.75 C 1708.28 417.44 1708.27 419.14 1708.25 420.84 C 1710.80 418.61 1713.45 416.20 1716.90 415.48 Z" />
    <path fill="#777676" d=" M 1746.13 420.13 C 1749.99 415.59 1756.65 413.93 1762.27 415.76 C 1765.06 416.79 1767.18 418.99 1769.37 420.93 C 1769.37 419.19 1769.38 417.45 1769.43 415.71 C 1772.54 415.72 1775.66 415.73 1778.79 415.78 C 1778.70 428.22 1778.85 440.66 1778.72 453.09 C 1778.68 458.39 1777.29 464.70 1772.06 467.21 C 1765.50 470.39 1757.65 470.09 1750.78 468.18 C 1745.55 466.66 1742.57 461.44 1742.61 456.20 C 1746.36 456.61 1750.11 457.14 1753.86 457.74 C 1756.14 462.38 1762.17 462.22 1766.20 460.19 C 1770.02 457.51 1768.17 452.14 1768.74 448.22 C 1764.71 453.40 1757.36 455.68 1751.29 452.82 C 1745.53 450.28 1742.36 444.01 1741.71 438.00 C 1741.11 431.82 1741.92 424.98 1746.13 420.13 Z" />
    <path fill="#777676" d=" M 1375.43 415.74 C 1378.69 415.72 1381.96 415.73 1385.25 415.74 C 1387.73 423.89 1389.57 432.26 1392.55 440.26 C 1394.62 432.08 1396.84 423.95 1398.90 415.78 C 1402.14 415.77 1405.44 415.41 1408.66 416.06 C 1411.16 424.03 1412.47 432.33 1415.06 440.28 C 1417.79 432.32 1419.56 424.06 1422.37 416.13 C 1425.56 415.30 1428.93 415.83 1432.20 415.77 C 1428.34 428.54 1424.08 441.19 1420.12 453.93 C 1416.89 453.89 1413.42 454.70 1410.37 453.59 C 1407.80 445.83 1406.26 437.75 1403.78 429.95 C 1401.39 437.87 1399.52 445.93 1397.37 453.91 C 1394.02 454.13 1390.68 454.11 1387.35 453.90 C 1383.55 441.12 1379.32 428.48 1375.43 415.74 Z" />
    <path fill="#777676" d=" M 1653.70 415.74 C 1657.20 415.72 1660.70 415.73 1664.22 415.78 C 1664.24 423.53 1664.14 431.28 1664.23 439.04 C 1664.26 441.52 1664.52 444.65 1667.07 445.89 C 1671.41 448.34 1677.58 445.05 1677.97 440.07 C 1678.80 431.98 1678.01 423.80 1678.41 415.68 C 1681.80 415.71 1685.21 415.73 1688.62 415.82 C 1688.57 428.52 1688.59 441.23 1688.62 453.93 C 1685.50 454.09 1682.38 454.11 1679.28 454.01 C 1679.27 452.34 1679.28 450.68 1679.30 449.03 C 1676.09 451.74 1672.47 454.46 1668.10 454.69 C 1662.98 455.29 1656.94 453.08 1655.01 447.94 C 1653.77 444.45 1653.71 440.68 1653.69 437.03 C 1653.71 429.93 1653.71 422.84 1653.70 415.74 Z" />
    <path fill="#fefefe" d=" M 1185.99 424.99 C 1189.01 422.10 1194.57 422.62 1196.90 426.13 C 1197.91 427.84 1198.30 429.83 1198.87 431.72 C 1193.61 431.94 1188.34 431.93 1183.09 431.77 C 1183.78 429.43 1184.11 426.74 1185.99 424.99 Z" />
    <path fill="#fefefe" d=" M 1300.40 423.66 C 1303.45 422.59 1307.16 423.11 1309.50 425.46 C 1313.02 428.57 1313.00 433.73 1312.57 438.04 C 1312.21 442.45 1308.60 446.54 1304.04 446.66 C 1299.10 447.12 1294.98 442.77 1294.46 438.09 C 1293.50 432.78 1294.73 425.80 1300.40 423.66 Z" />
    <path fill="#fefefe" d=" M 1517.09 424.99 C 1520.20 421.96 1526.16 422.68 1528.25 426.59 C 1529.00 428.23 1529.41 430.01 1529.92 431.75 C 1524.64 431.92 1519.36 431.95 1514.10 431.71 C 1514.83 429.38 1515.29 426.78 1517.09 424.99 Z" />
    <path fill="#fefefe" d=" M 1581.67 423.80 C 1586.27 421.76 1592.08 424.29 1593.63 429.11 C 1595.49 434.57 1595.04 442.19 1589.63 445.51 C 1585.21 448.27 1578.91 445.96 1577.07 441.17 C 1574.88 435.32 1575.44 426.95 1581.67 423.80 Z" />
    <path fill="#fefefe" d=" M 1757.37 423.62 C 1760.38 422.51 1764.09 423.24 1766.17 425.78 C 1768.67 428.53 1768.89 432.47 1768.74 436.00 C 1768.65 439.04 1767.57 442.23 1765.09 444.14 C 1761.90 446.87 1756.38 446.39 1754.01 442.83 C 1751.88 439.68 1751.98 435.68 1752.15 432.04 C 1752.20 428.59 1753.93 424.85 1757.37 423.62 Z" />
    <path fill="#fefefe" d=" M 1447.06 440.08 C 1450.72 438.77 1454.66 438.51 1458.51 438.15 C 1457.78 440.37 1458.22 443.50 1455.98 444.93 C 1452.65 447.16 1447.15 447.70 1444.68 443.98 C 1443.97 442.11 1445.40 440.64 1447.06 440.08 Z" />
  </g>
`]
